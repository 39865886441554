import rApi from '@src/http'
// import _ from 'lodash'
import { AuthDef } from '@src/pages/home/index.module'
import { message } from 'antd'
import moment from 'moment'
import { exportResToExcel, getRangeTime } from '@src/libs/logic'

export interface IState {
    conf: any;
    rangeTime: any[];
}

const initState = () => {
    return {
        open: false, // ConfModal是否打开显示
        conf: {}, // 配置
        details: {}, // 详情数据
        chartData: [],
        tableData: [],
        dateStart: null,
        dateEnd: null,
        rangeTime: [moment(), moment()],
        materialName: null,
        drawerVisible: false, // 是否显示drawer
        volumeTabKey: 'receiver', // 体量统计tabkey
    }
}

export default {
    namespace: 'p_home',
    state: {
        ...initState()
    },
    effects: {
        *getConfig ({ payload }: any, { put, call }: any) {
            try {
                const conf = yield call(rApi[AuthDef.main.serviceName])
                // yield call(sleep, 2000)
                // console.log('getConfig', conf)
                yield put({
                    type: 'upd',
                    payload: {
                        conf,
                        open: false
                    }
                })
            } catch (error) {
                console.log('getConfig Error', error)
            }
        },
        *changeConfig({ payload }: any, { put, call }: any) {
            try {
                yield call(rApi[AuthDef.set.serviceName], payload)
                message.success('配置成功')
                yield put({
                    type: 'upd',
                    payload: {
                        conf: payload,
                        open: false
                    }
                })
            } catch (error) {
                console.log('changeConfig Error', error)
            }
        },
        *getDetails ({ payload }: any, { put, call, select }: any) {
            try {
                const rangeTime = yield select((state: any) => state.p_home.rangeTime)
                const t = getRangeTime(rangeTime)
                const details = yield call(rApi['getHomeDetails'], { startTime: t.start, endTime: t.end })
                yield put({
                    type: 'upd',
                    payload: {
                        details
                    }
                })
            } catch (error) {
                console.log('getDetails Error', error)
            }
        },
        *getData ({ payload }: any, { put }: any) {
            try {
                yield put({
                    type: 'getConfig'
                })
                yield put({
                    type: 'getDetails'
                })
            } catch (error) {
                console.log('getData Error', error)
            }
        },
        *getVolumeStatistics ({ payload }: any, { call, select, put }: any) {
            try {
                const { dateStart, dateEnd, materialName, volumeTabKey } = yield select(({ p_home }: any) => ({
                    dateStart: p_home.dateStart,
                    dateEnd: p_home.dateEnd,
                    materialName: p_home.materialName,
                    volumeTabKey: p_home.volumeTabKey
                }))
                const t = getRangeTime([dateStart, dateEnd])
                // console.log('t', t)
                const api = volumeTabKey === 'receiver' ? 'getHomeReceiptData' : 'getHomeShipmentData'
                if (volumeTabKey === 'receiver') {
                    const { totalVolumeReceiptTableBo, totalVolumeReceiptStatisticsBo } = yield call(rApi[api], { dateStart: t.start, dateEnd: t.end, materialName })
                    yield put({
                        type: 'upd',
                        payload: {
                            chartData: totalVolumeReceiptStatisticsBo,
                            tableData: totalVolumeReceiptTableBo
                        }
                    })
                } else {
                    const data = yield call(rApi[api], { startTime: t.start, endTime: t.end })
                    yield put({
                        type: 'upd',
                        payload: {
                            chartData: data,
                            tableData: data
                        }
                    })
                }
            } catch (error) {
                console.log('getVolumeStatistics Error', error)
                yield put({
                    type: 'upd',
                    payload: {
                        chartData: [],
                        tableData: []
                    }
                })
            }
        },
        *export({ payload }: any, { select, call }: any) {
            try {
                const { volumeTabKey, dateStart, dateEnd, materialName } = yield select(({ p_home }: any) => ({
                    volumeTabKey: p_home.volumeTabKey,
                    dateStart: p_home.dateStart,
                    dateEnd: p_home.dateEnd,
                    materialName: p_home.materialName,
                }))
                const api = volumeTabKey === 'receiver' ? AuthDef.receiptExport.serviceName : AuthDef.shipmentExport.serviceName
                const res = yield call(rApi[api], { dateStart, dateEnd, materialName })
                yield exportResToExcel(res)
            } catch (error) {
                console.error('err', error)
            }
        }
    },
    reducers: {
        upd(state: IState, { payload }: any) {
            // console.log('upd', payload)
            return {
                ...state,
                ...payload
            }
        },
        reset(state: IState, { payload }: any) {
            return {
                ...state,
                ...initState(),
                ...payload
            }
        }
    }
};