import dva from 'dva';
import createLoading from 'dva-loading';

import global from './global';
import p_home from './pages/home';
import p_transport from './pages/transport';
import p_warehouse from './pages/warehouse';

export const app = dva({
    onError(err) {
        // console.error('dva onError', err)
        err.preventDefault();
    }
})
app.use(createLoading({
    loading: {
        global: false,
        models: {}
    }
}))
export const initGlobalModels = () => {
    app.model(global);
    app.model(p_home);
    app.model(p_transport);
    app.model(p_warehouse);
}
