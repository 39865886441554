import rApi from '@src/http'
import { AuthDef } from '@src/pages/monitor/transport/index.module'
// import _ from 'lodash'
// import { message } from 'antd'
// import moment from 'moment'
// import { sleep } from '@src/libs/util';

function initState (): any {
    return {
        orderNo: null, // 订单号搜索
        status: null, // 状态搜索
        handleStatus: null, // 是否异常
        dateOrderName: null, // 筛选时间类型
        dateStart: null, // 时间start
        dateEnd: null, // 时间end
    }
}

export default {
    namespace: 'p_transport',
    state: {
        ...initState(),
        statistics: {},
        tableData: [],
        total: 0,
        receiptVisible: false,
        evaluateVisible: false,
        drawerVisible: false,
        activeRow: {}, // 当前查看行
        logs: [], // 日志信息
        showDetails: false,
        trackDetails: null // 追踪详情数据
    },
    effects: {
        *getStatistics ({ payload }: any, { put, call, select }: any) {
            try {
                const {
                    orderNo,
                    status,
                    handleStatus,
                    dateOrderName,
                    dateStart,
                    dateEnd
                } = yield select(({ p_transport }: any) => ({
                    orderNo: p_transport.orderNo,
                    status: p_transport.status,
                    handleStatus: p_transport.handleStatus,
                    dateOrderName: p_transport.dateOrderName,
                    dateStart: p_transport.dateStart,
                    dateEnd: p_transport.dateEnd,
                }))
                const statistics = yield call(rApi['getTransportStatis'], {
                    orderNo,
                    status,
                    handleStatus,
                    dateOrderName,
                    dateStart,
                    dateEnd
                })
                yield put({
                    type: 'upd',
                    payload: {
                        statistics
                    }
                })
            } catch (error) {
                console.error('getStatistics Error', error)
                yield put({
                    type: 'upd',
                    payload: {
                        statistics: {}
                    }
                })
            }
        },
        *getTableData({ payload }: any, { select, call, put }: any) {
            try {
                const {
                    orderNo,
                    status,
                    handleStatus,
                    dateOrderName,
                    dateStart,
                    dateEnd
                } = yield select(({ p_transport }: any) => ({
                    orderNo: p_transport.orderNo,
                    status: p_transport.status,
                    handleStatus: p_transport.handleStatus,
                    dateOrderName: p_transport.dateOrderName,
                    dateStart: p_transport.dateStart,
                    dateEnd: p_transport.dateEnd,
                }))
                const ret = yield call(rApi[AuthDef.main.serviceName], {
                    orderNo,
                    status,
                    handleStatus,
                    dateOrderName,
                    dateStart,
                    dateEnd,
                    ...payload
                })
                const tableData = ret.records || []
                const total = ret.total || 0
                yield put({
                    type: 'upd',
                    payload: {
                        tableData,
                        total
                    }
                })
            } catch (error) {
                console.error('getTableData Error', error)
                yield put({
                    type: 'upd',
                    payload: {
                        tableData: [],
                        total: 0
                    }
                })
            }
        },
        *getReceipt ({ payload }: any, { put, call, select }: any) {
            try {
                const {
                    activeRow
                } = yield select(({ p_transport }: any) => ({
                    activeRow: p_transport.activeRow
                }))
                yield call(rApi['getTransportReceipt'], {
                    id: activeRow.id,
                    orderType: activeRow.orderType
                })
            } catch (error) {
                console.error('getReceipt Error', error)
            }
        },
        *getTransportTrack({ payload }: any, { all, call, put }: any) {
            try {
                const { activeRow, drawerVisible } = payload
                yield put({
                    type: 'upd',
                    payload: {
                        activeRow,
                        drawerVisible
                    }
                })
                const { logs, trackDetails } = yield all ({
                    logs: yield call(rApi['getTransportTrackById'], activeRow.id),
                    trackDetails: yield call(rApi['getTransportTrack'], { id: activeRow.id, orderType: activeRow.orderType })
                })
                // console.log('r', logs, trackDetails)
                yield put({
                    type: 'upd',
                    payload: {
                        logs,
                        trackDetails
                    }
                })
            } catch (error) {
                console.error('getTransportTrack Error', error)
                yield put({
                    type: 'upd',
                    payload: {
                        activeRow: {},
                        logs: [],
                        trackDetails: null
                    }
                })
            }
        },
        // *getTransportOne ({ payload }: any, { put, call, select }: any) {
        //     try {
        //         const {
        //             activeRow
        //         } = yield select(({ p_transport }: any) => ({
        //             activeRow: p_transport.activeRow
        //         }))
        //         yield call(rApi['getTransportById'], activeRow.id)
        //     } catch (error) {
        //         console.error('getTransportOne Error', error)
        //     }
        // },
    },
    reducers: {
        reset(state: any) {
            return {
                ...state,
                ...initState()
            }
        },
        clear(state: any) {
            return {
                ...state,
                ...initState(),
                statistics: {},
                receiptVisible: false,
                evaluateVisible: false,
                drawerVisible: false,
                activeRow: {}, // 当前查看行
                logs: [], // 日志信息
                trackDetails: null // 追踪详情数据
            }
        },
        upd(state: any, { payload }: any) {
            // console.log('upd', payload)
            return {
                ...state,
                ...payload
            }
        }
    }
};