import _ from 'lodash'
import rApi from '@src/http'
import { IDvaModel } from '@src/typings/models'
import { auth } from '@src/libs/load-services'
import {
    fullscreenDomId,
    headerHeight,
    unCollapsedWidth
} from '@src/config'
import { isFullscreen, localRead, localSet, localRemove } from '@src/libs/util'
import {
    authToTree,
    treeToList,
    onlineTreeConvert,
    checkAndFilterOnlinePower,
    getAllModules
} from '@src/libs/logic'
import { routerRedux } from 'dva/router'
import { homeId } from '@src/config'

interface IMinPower {
    id: string | null;
    path: string;
}
export interface IPower extends IMinPower {
    name: string;
    parent: string | null;
    type: string;
    serviceName?: string;
    method?: string;
    url?: string;
    sort?: number;
    children?: IPower[];
    Component?: any;
}

export type powerType = IPower | IMinPower

export interface IPage {
    main: IPower;
    children?: IPower[];
}

export interface IAuth<T> {
    [propName: string]: T
}

export interface IOnlinePower {
    id: number;
    parentId: number;
    children: IOnlinePower[];
    icon: string | null;
    name: string;
    url: string | null;
    spread: boolean;
    path: string | null;
    component: string | null;
    authority: string | null;
    redirect: string | null;
    code: string;
    type: string;
    label: string;
    sort: number | null;
}

export interface IGlobalState {
    LOGIN_TYPE: number; // 0：未知； 1：普通登录； 2：订单查询登录
    userName?: string;
    accessToken?: string | null;
    isLogin: boolean;
    isOpenEnterAnim: boolean;
    collapsed: boolean;
    siderWidth: number;
    isFullScreening: boolean;
    tabsList: powerType[];
    activeTab: powerType;
    conHeight: number;
    localTree: IPower[];
    localAuthList: IPower[];
    onlineTree: IPower[];
    onlineAuthList: IPower[];
    authErrorType: number;
    moduleRefresh: boolean;
    allModules: any;
    pageDatas: any;
    companyInfo: any;
}

function initState(): IGlobalState {
    const userName: string | undefined = localRead('userName')
    const accessToken: string | undefined = localRead('accessToken')
    const isLogin: boolean = !!accessToken
    const lt = isLogin ? localRead('LOGIN_TYPE') : 0
    const LOGIN_TYPE: number = lt ? parseInt(lt, 10) : 0
    const isOpenEnterAnim: boolean = !isLogin
    const localTree = authToTree(auth)
    const localAuthList = treeToList(localTree)
    return {
        userName,
        accessToken,
        isLogin,
        LOGIN_TYPE,
        isOpenEnterAnim,
        collapsed: false, // 侧边栏是否折叠
        siderWidth: unCollapsedWidth, // 侧边栏宽度
        isFullScreening: false, // 当前的 fullscreenDom 是否全屏
        tabsList: [], // 当前打开的tab页签列表
        activeTab: { id: null, path: '' }, // 当前激活的tab页签,
        pageDatas: {},
        conHeight: window.innerHeight - headerHeight, // content区域height
        localTree,
        localAuthList,
        onlineTree: [],
        onlineAuthList: [],
        authErrorType: 0,
        moduleRefresh: false,
        allModules: [],
        companyInfo: {}
    }
}

const blankPath = '/_blank'

const model: IDvaModel<IGlobalState> =  {
    namespace: 'global',
    state: initState(),
    effects: {
        *getCompanyInfo({ payload }, {call, put}) {
            // console.log('getCompanyInfo')
            try {
                yield put({
                    type: 'setGlobalState',
                    payload: {
                        companyInfo: {
                            loading: true,
                            loadError: false
                        }
                    }
                })
                const info = yield call(rApi.getCompanyInfo)
                // console.log('info', info)
                yield put({
                    type: 'setGlobalState',
                    payload: {
                        companyInfo: {
                            ...info,
                            loading: false
                        }
                    }
                })
            } catch (e) {
                yield put({
                    type: 'setGlobalState',
                    payload: {
                        companyInfo: {
                            loading: false,
                            loadError: true
                        }
                    }
                })
            }
            
        },
        *routerTo({ payload }, { put }) {
            yield put(routerRedux.push(payload.path))
        },
        *closeTheTab({ payload: { tabIndex } }: any, { put, select }) {
            const tabsList = yield select(({ global }: any) => global.tabsList )
            const newTabsList = [...tabsList]
            // const tabIndex = _.find(newTabsList, item => item.id === tab.id)
            let closeItem = null
            // console.log('tabIndex', tabIndex)
            if (newTabsList[tabIndex]) {
                closeItem = newTabsList.splice(tabIndex, 1)[0]
            } else {
                console.error('关闭错误，未发现待关闭标签')
                closeItem = { id: null }
            }
            const i = tabIndex - 1 > -1 ? tabIndex - 1 : 0
            const newActiveTab = newTabsList[i] ? newTabsList[i] : { id: null, path: blankPath }
            if (closeItem.id !== newActiveTab.id && newActiveTab.path) {
                yield put({ type: 'routerTo', payload: { path: newActiveTab.path } })
            }
            yield put({
                type: 'setGlobalState',
                payload: { tabsList: newTabsList, activeTab: newActiveTab }
            })
        },
        *closeTab({ payload: { tab, tabIndex, type }}: any, { select, put }) {
            const { tabsList, activeTab } = yield select(({ global }: any) =>  ({ tabsList: global.tabsList, activeTab: global.activeTab }))
            let newTabsList = [...tabsList]
            let newActiveTab = { ...activeTab }
            let len = newTabsList.length
            // console.log(type, newTabsList, homeId, tab)
            if (type === 'closeLeft') {
                newTabsList = newTabsList.filter((item, itemIndex) => (item.id === homeId || itemIndex >= tabIndex))
                len = newTabsList.length
                if (!_.some(newTabsList, item => (item.id === activeTab.id))) {
                    newActiveTab = newTabsList[1] || newTabsList[0] || { path: blankPath }
                }
            } else if (type === 'closeRight') {
                newTabsList = newTabsList.slice(0, tabIndex + 1)
                len = newTabsList.length
                if (!_.some(newTabsList, item => item.id === activeTab.id)) {
                    newActiveTab = newTabsList[len - 1] || { path: blankPath }
                }
            } else if (type === 'closeOther') {
                newTabsList = newTabsList.filter(item => (item.id !== tab.id || item.id !== homeId))
                newActiveTab = { ...tab }
            } else if (type === 'closeAll') {
                newTabsList = newTabsList.filter(item => (item.id === homeId))
                newActiveTab = newTabsList[0] || { id: '', path: '_blank' }
            }
            if (activeTab.id !== newActiveTab.id && newActiveTab.path) {
                yield put({ type: 'routerTo', payload: { path: newActiveTab.path } })
            }
            yield put({
                type: 'setGlobalState',
                payload: { tabsList: newTabsList, activeTab: newActiveTab }
            })
        },
        *menuClick({ payload }: any, { select, put }) {
            const { item, pageData } = payload
            const { activeTab, tabsList, pageDatas } = yield select(({ global }: any) => ({
                tabsList: global.tabsList,
                activeTab: global.activeTab,
                pageDatas: global.pageDatas
            }))
            if (activeTab.id === item.id) {
                return
            }
            const isTabExist = _.some(tabsList, ({ id }) => {
                return id === item.id
            })
            if (isTabExist) {
                yield put({
                    type: 'setGlobalState',
                    payload: {
                        activeTab: item,
                        pageDatas: {
                            ...pageDatas,
                            [item.id]: pageData || pageDatas[item.id]
                        }
                    }
                })
            } else {
                const newTabsList = [...tabsList, item]
                yield put({
                    type: 'setGlobalState',
                    payload: {
                        activeTab: item,
                        tabsList: newTabsList,
                        pageDatas: {
                            ...pageDatas,
                            [item.id]: pageData || pageDatas[item.id]
                        }
                    }
                })
            }
            yield put({ type: 'routerTo', payload: { path: item.path } })
        },

        *login({ payload }, { call, put }) {
            try {
                const { type, ...restParams } = payload
                const target = type === 'client' ? rApi.Login : rApi.codeLogin
                let LOGIN_TYPE = type === 'client' ? 1 : 2
                const res = yield call(target, restParams)
                if (!!res.companyId && LOGIN_TYPE === 2) {
                    LOGIN_TYPE = 1
                }
                const userName = res.username || '-'
                const accessToken = res.access_token
                // console.log('login', LOGIN_TYPE)
                localSet('userName', userName)
                localSet('accessToken', accessToken)
                localSet('LOGIN_TYPE', LOGIN_TYPE)
                yield put({
                    type: 'setGlobalState',
                    payload: {
                        isLogin: true,
                        userName,
                        accessToken,
                        LOGIN_TYPE
                    }
                })
                yield put({ type: 'routerTo', payload: { path: '/' } })
            } catch (error) {
                throw error
            }
        },
        *logout({ payload }, { put, select}) {
            const { companyInfo } = yield select(({ global }: any) => ({ companyInfo: global.companyInfo }))
            localRemove('userName')
            localRemove('accessToken')
            localRemove('LOGIN_TYPE')
            yield put({
                type: 'setGlobalState',
                payload: {
                    ...initState(),
                    companyInfo
                }
            })
        },
        *initAuth({ payload }, { call, put, select }) {
            try {
                const { homeTab, initTab } = payload
                const ret: IOnlinePower[] = (yield call(rApi.getUserPermisson, {}) || [])
                if (!ret || ret.length < 1) {
                    throw Error('权限为空！')
                }
                const localAuthList = yield select(({ global }: any) => global.localAuthList)
                const onlineTree: IPower[] = onlineTreeConvert(ret, localAuthList)
                let onlineAuthList: IPower[] = treeToList(onlineTree)
                onlineAuthList = checkAndFilterOnlinePower(localAuthList, onlineAuthList)
                const allModules = getAllModules(onlineAuthList)
                const tabsList = homeTab ? [homeTab] : []
                let activeTab = homeTab
                if (process.env.NODE_ENV !== 'production' && !!initTab) {
                    tabsList.push(initTab)
                    activeTab = initTab
                }
                yield put({
                    type: 'setGlobalState',
                    payload: {
                        onlineTree,
                        onlineAuthList,
                        allModules,
                        authErrorType: 0,
                        tabsList,
                        activeTab
                    }
                })
            } catch (e) {
                console.error('获取权限失败：', e, e.message)
                if (e.message === '权限为空！') {
                    yield put({
                        type: 'setGlobalState',
                        payload: {
                            authErrorType: 2,
                            tabsList: ['HOME']
                        }
                    })
                } else {
                    yield put({
                        type: 'setGlobalState',
                        payload: {
                            authErrorType: 1
                        }
                    })
                }
            }
        },
    },

    reducers: {
        updatePageData(state: IGlobalState, { payload }: any) {
            const pageDatas = {
                ...state.pageDatas,
                [payload.id]: {
                    ...state.pageDatas[payload.id],
                    ...payload.pageData
                }
            }
            console.log('updatePageData', payload, pageDatas)
            return {
                ...state,
                pageDatas
            }
        },
        setGlobalState (state: IGlobalState, { payload }: any) {
            return {
                ...state,
                ...payload
            }
        },
        fullscreen(state: IGlobalState) {
            // console.log('fullscreen', state)
            const i: any = document.getElementById(fullscreenDomId)
            if (!i) {
                return
            }
            if (i.requestFullscreen) {
                i.requestFullscreen();
            } else if (i.webkitRequestFullscreen) {
                i.webkitRequestFullscreen();
            } else if (i.mozRequestFullScreen) {
                i.mozRequestFullScreen();
            } else if (i.msRequestFullscreen) {
                i.msRequestFullscreen();
            } else {
                return
            }
            return {
                ...state,
                isFullScreening: true
            }
        },
        /**
         * 退出全屏
         */
        exitFullscreen (state: IGlobalState) {
            // console.log('exitFullscreen', state)
            const doc: any = document
            if (isFullscreen()) {
                if (doc.exitFullscreen) {
                    doc.exitFullscreen();
                } else if (doc.webkitExitFullscreen) {
                    doc.webkitExitFullscreen();
                } else if (doc.mozCancelFullScreen) {
                    doc.mozCancelFullScreen();
                } else if (doc.msExitFullscreen) {
                    doc.msExitFullscreen();
                } else {
                    return
                }
            }
            return {
                ...state,
                isFullScreening: false
            }
        },
        addOneTab (state: IGlobalState, { payload }: any) {
            const { tabsList } = state
            const newTabsList = [...tabsList, payload]
            return {
                ...state,
                tabsList: newTabsList
            }
        },
        updateTabsList(state: IGlobalState, { payload }: any) {
            return { ...state, tabsList: payload }
        },
        closeOtherOneTab(state: IGlobalState, { payload: { tab, tabIndex } }: any) {
            const { tabsList } = state
            const newTabsList = [...tabsList]
            // const tabIndex = _.find(newTabsList, item => item.id === tab.id)
            if (newTabsList[tabIndex]) {
                newTabsList.splice(tabIndex, 1)
            }
            return { ...state, tabsList: newTabsList }
        },
    }
}

export default model
