import rApi from '@src/http'
import { AuthDef } from '@src/pages/monitor/warehouse/index.module'
import { exportResToExcel } from '@src/libs/logic'

function initState (): any {
    return {
        warehouseName: null, // 仓库
        status: null, // 状态搜索
        singleNumber: null, // 收货单号
        dateOrderName: null, // 筛选时间类型
        dateStart: null, // 时间start
        dateEnd: null, // 时间end
        supplier: null, // 供应商
        warehouseAreaName: null , // 仓库区域
        clientName: null, // 客户名称
        materialName: null, // 物料名称
        location: null, // 库位
        handleStatus: null, // 是否异常
        frozenStatus: null, // 是否冻结
    }
}

export default {
    namespace: 'p_warehouse',
    state: {
        ...initState(),
        activeKey: 'receiver', // tab
    },
    effects: {
        *getStatistics ({ payload }: any, { put, call, select }: any) {
            try {
                const {
                    warehouseName,
                    status,
                    singleNumber,
                    dateOrderName,
                    dateStart,
                    dateEnd,
                    supplier,
                    warehouseAreaName,
                    clientName,
                    materialName,
                    handleStatus,
                    location,
                    frozenStatus,
                    activeKey,
                } = yield select(({ p_warehouse }: any) => ({
                    warehouseName: p_warehouse.warehouseName,
                    status: p_warehouse.status,
                    singleNumber: p_warehouse.singleNumber,
                    dateOrderName: p_warehouse.dateOrderName,
                    dateStart: p_warehouse.dateStart,
                    dateEnd: p_warehouse.dateEnd,
                    supplier: p_warehouse.supplier,
                    warehouseAreaName: p_warehouse.warehouseAreaName,
                    clientName: p_warehouse.clientName,
                    materialName: p_warehouse.materialName,
                    handleStatus: p_warehouse.handleStatus,
                    location: p_warehouse.location,
                    frozenStatus: p_warehouse.frozenStatus,
                    activeKey: p_warehouse.activeKey
                }))
                let statistics = null
                if (activeKey === 'receiver') {
                    statistics = yield call(rApi['getReceiptStatis'], {
                        warehouseName,
                        status,
                        singleNumber,
                        dateOrderName,
                        dateStart,
                        dateEnd,
                        supplier,
                        warehouseAreaName
                    })
                } else if (activeKey === 'sender') {
                    statistics = yield call(rApi['getShipmentStatis'], {
                        warehouseName,
                        status,
                        singleNumber,
                        dateOrderName,
                        dateStart,
                        dateEnd,
                        clientName,
                        warehouseAreaName
                    })
                } else {
                    statistics = yield call(rApi['getInventoryStatis'], {
                        warehouseName,
                        warehouseAreaName,
                        materialName,
                        location,
                        supplier,
                        status,
                        handleStatus,
                        frozenStatus,
                    })
                }
                yield put({
                    type: 'upd',
                    payload: {
                        statistics
                    }
                })
            } catch (error) {
                console.error('getStatistics Error', error)
                yield put({
                    type: 'upd',
                    payload: {
                        statistics: {}
                    }
                })
            }
        },
        *export({ payload }: any, { select, call }: any) {
            try {
                const activeKey = yield select(({ p_warehouse }: any) => p_warehouse.activeKey)
                const api = activeKey === 'receiver' ? AuthDef.export.serviceName : activeKey === 'sender' ? AuthDef.shipmentExport.serviceName : AuthDef.inventoryExport.serviceName
                const res = yield call(rApi[api], payload)
                yield exportResToExcel(res)
            } catch (error) {
                console.error('err', error)
            }
        }
    },
    reducers: {
        reset(state: any) {
            return {
                ...state,
                ...initState()
            }
        },
        clear(state: any) {
            return {
                ...state,
                ...initState(),
                activeKey: 'receiver',
            }
        },
        changeTab(state: any, { payload }: any) {
            return {
                ...state,
                ...initState(),
                activeKey: payload,
            }
        },
        upd(state: any, { payload }: any) {
            // console.log('upd', payload)
            return {
                ...state,
                ...payload
            }
        }
    }
};